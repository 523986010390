.intro {
  grid-area: intro;
}

.skills {
  grid-area: skills;
}

.experience {
  grid-area: experience;
}

.community-contributions {
  grid-area: community;
}

.grid-container {
  display: grid;
  gap: 1rem 3rem;
  max-width: 1000px;
  margin: auto;
  padding: 0 5vw;

  grid-template-areas:
    'intro'
    'experience'
    'community';
}

.badge {
  display: inline-block;
  margin: 0 1em;
}

/* Smartphone */
@media (max-width: 767px) {
}

/* Tablet */
@media (min-width: 768px) and (max-width: 1023px) {
}

/* Desktop */
@media (min-width: 1024px) {
}
