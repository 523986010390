.profile-picture {
  border-radius: 50%;
  border-color: var(--main-fg-color);
  border-style: solid;
  border-width: 5px;
}

/* Desktop */
@media (min-width: 1024px) {
  .picture-container {
    display: inline-block;
    width: 8em;
    height: 8em;
    padding-top: 0.5em;
  }

  .column-item p {
    display: inline-block;
  }
}

@media (max-width: 767px) {
  .picture-container {
    display: block;
    width: 40vw;
    height: 40vw;
  }

  .column-item p {
    display: block;
  }
}