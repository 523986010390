html {
  box-sizing: border-box;
  font-size: calc(1em + 0.5vw);

  --main-bg-color: #071330;
  --main-fg-color: #c3ceda;
  --accent-color-dark: #0c4160;
  --accent-color-light: #738fa7;
}

html > * {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--main-fg-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  text-align: center;
  font-size: 2.5em;
  color: var(--accent-color-light);
}

h2 {
  text-align: center;
  font-size: 1.75em;
  color: var(--accent-color-light);
}

h3 {
  font-size: 1.25em;
}

h4 {
  font-size: 1.10em;
}

a:link {
  color: var(--main-fg-color);
}

a:visited {
  color: var(--accent-color-light);
}

a:hover {
  color: var(--accent-color-light);
}

a:active {
  color: var(--main-fg-color);
}

img {
  max-width: 100%;
}

/** Common classes **/

.background {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: var(--main-bg-color);
  background: linear-gradient(
    0deg,
    rgba(12, 65, 96, 1) 0%,
    rgba(7, 19, 48, 1) 100%
  );
}

.column-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.column-item {
  margin: auto;
}

.row-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.row-item {
  margin: 1em;
}

/** Media queries **/

/* Smartphone */
@media (max-width: 767px) {
  .row-container {
    flex-direction: column;
  }
}

/* Tablet */
@media (min-width: 768px) and (max-width: 1023px) {
}

/* Desktop */
@media (min-width: 1024px) {
  html {
    font-size: 1em;
  }
}
