.extra-info {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  gap: 1em;
}

.company-entry {
  display: grid;
  grid: 'small-info details accordion-icon' / 1fr 3fr 2em;
}

.company-entry:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.small-info {
  grid-area: small-info;
  padding-top: 1.5em;
}

.small-info > div {
  margin-bottom: 0.5em;
}

.details {
  grid-area: details;
}

.accordion-icon {
  grid-area: accordion-icon;
  padding-top: 1.5em;
  justify-self: end;
}

/* Smartphone */
@media (max-width: 767px) {
  .company-entry {
    grid:
      'small-info details'
      'accordion-icon accordion-icon' /
      1fr 3fr;
  }

  .accordion-icon {
    grid-area: accordion-icon;
    justify-self: center;
  }
}
